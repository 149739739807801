@import './src/styles/mixins';

.burger-drop {
  @media (min-width: 1025px) {
    display: none;
  }
  width: 20%;
  @include flex('center', 'right');

  .toggle-drop {
    background-color: #131313;
    width: 56px;
    height: 40px;
    border: none;
    box-sizing: border-box;
    @include flex('center', 'center');
    flex-direction: row;
    box-shadow: none;
    position: relative;

    &:focus, &:active, &:hover {
      box-shadow: none;
      background-color: #131313;
    }
  }

  .dropdown-menuBurger {
    padding: 0 5%;
    background-color: #131313;
    z-index: 30;
    width: 100%;
    height: 1025px;
    overflow: hidden;
    position: absolute;
    @include flex('center', '');
    margin-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    .list {
      @include flex('center', '');
      flex-direction: column;
      padding: 0;
      gap: 28px;
      width: 100%;

      .burger-item {
        @include flex('center', 'center');
        width: 100%;
        height: 50px;
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 160%;
        font-feature-settings: 'liga' off;
        color: #FFFFFF;
        text-decoration: none;
        @media screen and (max-width: 480px) {
          height: 20px;
          font-size: 20px;
        }
      }

      .burger-item:hover,
      .burger-item:focus {
        font-weight: 800;
        color: var(--color-primary);
      }

      img {
        width: 100%;
      }

      a {
        text-decoration: none;
        color: #ffffff
      }

      .language-block {
        display: flex;
        width: 100%;
        height: 50px;

        .language-block-item, div {
          width: 50%;
          @include flex('center', 'center');
          font-family: 'Inter', serif;
          font-style: normal;
          font-weight: 300;
          font-size: 32px;
          line-height: 160%;
          font-feature-settings: 'liga' off;
          color: #FFFFFF;
          text-decoration: none;
          @media screen and (max-width: 480px) {
            height: 20px;
            font-size: 20px;
          }
        }

        .active {
          font-weight: 600;
        }

        @media screen and (max-width: 480px) {
          height: 20px;
        }
      }

      @include offerButton();
      @media screen and (min-width: 601px) {
        .btn-header {
          display: none;
        }
      }

      .phone {
        width: 100%;
        height: 56px;
        @include flex('center', 'center');
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 140%;
        text-align: right;
        text-transform: uppercase;
        font-feature-settings: 'liga' off;
        color: var(--color-primary);
        text-decoration: none;
        margin-top: 50px;
        @media screen and (max-width: 480px) {
          margin-top: 10px;
          font-size: 28px;
        }
      }

      .phone:hover {
        color: white;
      }
    }

    @media screen and (max-width: 576px) {
      margin-top: 5px;
    }

    @media screen and (max-width: 480px) {
      height: 563px;
    }
  }

  .hide-dropdown {
    display: none;
  }
}