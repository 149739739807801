@import './src/styles/mixins';

:root {
  --color-primary: #fe6a00;
}

.footer {
  width: 100%;
  margin-top: 100px;
}

.container-top-wrapper {
  width: 100%;
  height: 119px;
  border-bottom-width: 0.01px;
  border-bottom-style: solid;
  border-bottom-color: #3e3e3e;
  border-top-width: 0.01px;
  border-top-style: solid;
  border-top-color: #3e3e3e;
  @include flex('center', 'center');
  .container-top {
    @include flex('center', 'space-between');
    max-width: 1440px;
    width: 100%;
    padding: 0 150px;

    .logo-block {
      .logo {
        width: 130%;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }

    .phone {
      height: 56px;
      font-family: "Inter", serif;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 140%;
      text-align: right;
      text-transform: uppercase;
      font-feature-settings: "liga" off;
      color: var(--color-primary);
      text-decoration: none;
      @include flex('center', 'center');
      @media screen and (max-width: 1025px) {
        font-size: 28px;
      }
      @media screen and (max-width: 600px) {
        font-size: 20px;
      }
    }

    .phone:hover {
      color: #fa8c3d;
    }

    @media screen and (min-width: 1441px) {
      padding: 0;
    }

    @media screen and (max-width: 1025px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      padding: 0 15px;
    }
  }
}

.container-bottom-wrapper {
  width: 100%;
  height: 119px;
  @include flex('center', 'center');
  .container-bottom {
    @include flex('center', 'space-between');
    max-width: 1440px;
    width: 100%;
    padding: 0 150px;

    .email {
      height: 56px;
      font-family: "Inter", serif;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 140%;
      text-transform: uppercase;
      font-feature-settings: "liga" off;
      text-decoration: none;
      color: var(--color-primary);
      @include flex('center', 'center');
      @media screen and (max-width: 1140px) {
        font-size: 28px;
      }
      @media screen and (max-width: 600px) {
        font-size: 20px;
      }
    }

    .email:hover {
      color: #fa8c3d;
    }

    .policy-block {
      width: 25%;
      @include flex('center', 'space-between');

      .link-imprint {
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 175%;
        text-decoration: none;
        font-feature-settings: "liga" off;
        color: #ffffff;
      }

      .link-data {
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 175%;
        text-decoration: none;
        font-feature-settings: "liga" off;
        color: #ffffff;
      }

      .link-imprint:hover {
        color: #fa8c3d;
      }

      .link-data:hover {
        color: #fa8c3d;
      }

      @media screen and (max-width: 1140px) {
        width: 30%;
      }

      @media screen and (max-width: 800px) {
        width: 32%;
      }
      @media screen and (max-width: 730px) {
        width: 50%;
      }
      @media screen and (max-width: 431px) {
        width: 70%;
      }
      @media screen and (max-width: 321px) {
        width: 90%;
      }
    }

    @media screen and (min-width: 1441px) {
      padding: 0;
    }

    @media screen and (max-width: 1025px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 600px) {
      padding: 0 15px;
    }

    @media screen and (max-width: 730px) {
      flex-direction: column;
      justify-content: center;
    }
  }
}