@import './src/styles/mixins';

:root {
  --color-primary: #fe6a00;
}

.capabilities-content {
  width: 100%;
  margin-top: 100px;

  &:before {
    display: block;
    content: "";
    height: 100px;
    margin: -100px 0 0;
  }

  .capabilities-title {
    max-width: 1440px;
    width: 100%;
    height: 67px;
    font-family: "Inter", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    text-transform: uppercase;
    font-feature-settings: "liga" off;
    color: var(--color-primary);
    margin: 0 150px 130px 150px;
    @media screen and (max-width: 1025px) {
      margin: 0 40px 130px 40px;
      font-size: 40px;
    }
    @media screen and (max-width: 600px) {
      margin: 0 15px 130px 15px;
      font-size: 36px;
    }
  }

  .native-app-block {
    width: 100%;
    position: relative;
    right: 0;
    max-width: 1440px;
    @include flex('', 'right');

    .stroke-native {
      position: absolute;
      height: 68px;
      z-index: 10;
      margin: 0 10%;
      left: -70px;
      top: -20px;
      max-width: 1440px;

      img {
        width: 346px;
        height: 68px;
        @media screen and (max-width: 375px) {
          width: 305px;
          height: 60px;
        }
      }

      h2 {
        position: absolute;
        height: 45px;
        left: 30%;
        top: 20%;
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 160%;
        text-align: center;
        text-transform: uppercase;
        font-feature-settings: "liga" off;
        color: white;
        @media screen and (max-width: 1025px) {
          font-size: 26px;
        }
        @media screen and (max-width: 600px) {
          font-size: 24px;
        }
      }

      @media screen and (min-width: 1598px) {
        left: -3%;
      }

      @media screen and (max-width: 1025px) {
        top: -55px;
        left: -4%;
        margin: 0;
      }

      @media screen and (max-width: 600px) {
        top: -52px;
        left: -3%;
      }

      @media screen and (max-width: 391px) {
        left: -5%;
      }
    }

    .rectangle-native-block {
      display: flex;
      flex-direction: column;
      width: 73%;

      .rectangle-native {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 260px;
        background: var(--color-primary);
        @include flex('center', '');

        .text-native {
          width: 297px;
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 175%;
          font-feature-settings: "liga" off;
          color: #ffffff;
          margin: 67px 0 67px 28px;
          @media screen and (max-width: 1025px) {
            font-size: 22px;
            margin: 48px 0 48px 40px;
          }
          @media screen and (max-width: 600px) {
            font-size: 20px;
            margin: 40px 18px 87px 18px;
            width: 100%;
          }
        }

        .phones-block {
          position: relative;
          width: 70%;
          display: flex;
          left: -2%;
          height: 100%;

          .phones {
            width: 100%;
            position: relative;
            text-decoration: none;
            .phones-image {
              width: 100%;
              position: relative;
            }

            .first-phone-signature {
              position: relative;
              display: flex;
              width: 100%;
              z-index: 11;
              transform: rotate(19deg);
              bottom: -25%;
              left: -55px;
              font-size: 18px;
              @include flex('center', '');
              color: #ffffff;
              text-decoration: none;

              .icon1 {
                height: 11.95px;
                margin-right: 5px;
                @include flex('center', '');

                svg {
                  transform: rotate(-15deg);
                }
                @media screen and (max-width: 1025px) {
                  width: 13.62px;
                  height: 12.27px;
                }
                @media screen and (max-width: 431px) {
                  width: 14.65px;
                  height: 12.32px;
                }
              }

              .megaAccountability {
                text-decoration: none;
                @include flex('center', 'center');
              }

              @media screen and (max-width: 1290px) {
                left: -45px;
                bottom: -20%;
                font-size: 16px;
              }

              @media screen and (max-width: 1100px) {
                bottom: -15%;
              }

              @media screen and (max-width: 1025px) {
                bottom: -20%;
              }

              @media screen and (max-width: 800px) {
                left: -45px;
                bottom: -15%;
              }

              @media screen and (max-width: 600px) {
                left: -15px;
                bottom: -20%;
              }

              @media screen and (max-width: 431px) {
                font-size: 12px;
                left: -10px;
                bottom: -15%;
              }

              @media screen and (max-width: 376px) {
                left: -10px;
                bottom: -10%;
              }
            }

            &:hover {
              .first-phone-signature {
                color: #fa8c3d;
                svg {
                  stroke: #fa8c3d;
                }
              }
            }

            @media screen and (max-width: 1025px) {
              left: -15px;
            }
          }

          @media screen and (min-width: 1441px) {
            width: 35%;
          }

          @media screen and (max-width: 1290px) {
            left: -20px;
          }

          @media screen and (max-width: 1025px) {
            left: -40px;
            bottom: -100px;
          }

          @media screen and (max-width: 750px) {
            bottom: -75px;
            left: -30px;
          }

          @media screen and (max-width: 600px) {
            position: absolute;
            top: 65%;
            left: 15%;
            width: 80%;
          }
        }

        @media screen and (min-width: 1441px) {
          width: 200%;
        }

        @media screen and (max-width: 600px) {
          padding: 0;
          align-items: normal;
        }
      }

      .view-native {
        display: none;
        @media screen and (max-width: 600px) {
          text-decoration: none;
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 300;
          font-size: 10px;
          line-height: 17px;
          @include flex('center', '');
          font-feature-settings: "liga" off;
          color: #ffffff;
          margin-top: 10px;
        }
      }

      .second-view-native {
        text-decoration: none;
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        @include flex('center', '');
        font-feature-settings: "liga" off;
        color: #ffffff;
        margin-top: 10px;
        margin-left: 28px;
        @media screen and (max-width: 600px) {
          display: none;
        }
        @media screen and (max-width: 1025px) {
          margin-left: 40px;
        }
        @media screen and (max-width: 600px) {
          margin-left: 18px;
        }
      }

      @media screen and (max-width: 1025px) {
        width: 100%;
      }
    }
  }

  .web-app-block {
    display: flex;
    justify-content: space-between;
    margin-top: 300px;
    width: 100%;
    position: relative;
    max-width: 1440px;

    .web-app-content {
      width: 640px;

      .stroke-web-block {
        position: relative;

        .stroke-web {
          position: absolute;
          height: 70px;
          z-index: 10;
          left: 95%;
          top: -10%;

          img {
            width: 346px;
            height: 68px;
            @media screen and (max-width: 376px) {
              width: 305px;
              height: 60px;
            }
          }

          h2 {
            position: absolute;
            left: 35%;
            top: 10%;
            height: 45px;
            font-family: "Inter", serif;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 160%;
            text-align: center;
            text-transform: uppercase;
            font-feature-settings: "liga" off;
            color: #ffffff;
            @media screen and (max-width: 1025px) {
              font-size: 26px;
            }
            @media screen and (max-width: 600px) {
              font-size: 24px;
            }
          }

          @media screen and (max-width: 1025px) {
            left: 80%;
            top: -20%;
          }

          @media screen and (max-width: 769px) {
            left: 75%;
            top: -20%;
          }

          @media screen and (max-width: 720px) {
            left: -5%;
          }

          @media screen and (max-width: 391px) {
            left: -5%;
          }
        }

        .rectangle-web {
          box-sizing: border-box;
          width: 100%;
          left: 0;
          top: 722px;
          background: var(--color-primary);
          @include flex('center', '');

          .text-web {
            width: 402px;
            font-family: "Inter", serif;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 175%;
            font-feature-settings: "liga" off;
            color: #ffffff;
            margin: 67px 0 67px 150px;
            @media screen and (max-width: 1025px) {
              width: 370px;
              font-size: 22px;
              margin: 48px 100px 48px 40px;
            }
            @media screen and (max-width: 600px) {
              width: 100%;
              font-size: 20px;
              margin: 40px 18px 82px 18px;
            }
          }

          @media screen and (min-width: 1440px) {
            width: 200%;
            padding-right: 88px;
            transform: translateX(-50%);
            justify-content: end;
          }
        }

        .laptop-block {
          position: absolute;
          right: -15%;
          top: 60%;
          display: flex;
          justify-content: end;
          width: 461px;
          height: 320px;
          @media screen and (max-width: 1025px) {
            width: 403px;
            height: 280px;
          }
          @media screen and (max-width: 431px) {
            width: 327px;
            height: 228px;
          }
          .comp {
            width: 100%;
          }

          .webOffice {
            position: absolute;
            left: 0;
            top: 65%;
            text-decoration: none;
            font-family: "Inter", serif;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;
            @include flex('center', '');
            font-feature-settings: "liga" off;
            color: #ffffff;
            transform: rotate(-16deg);
            @media screen and (max-width: 1025px) {
              font-size: 16px;
              top: 65%;
            }
            @media screen and (max-width: 431px) {
              left: 2%;
              font-size: 12px;
            }
            @media screen and (max-width: 321px) {
              left: -2%;
            }
          }

          .icon2 {
            height: 11.95px;
            margin-right: 5px;
            transform: rotate(10deg);
            @include flex('center', '');
            @media screen and (max-width: 431px) {
              display: none;
            }
          }

          &:hover {
            .webOffice {
              color: #fa8c3d;
            }
            .icon2 {
              svg {
                fill: #fa8c3d;
                stroke: #fa8c3d;
              }
            }
          }

          @media screen and (max-width: 769px) {
            right: -30%;
          }
          @media screen and (max-width: 620px) {
            right: -20%;
          }
          @media screen and (max-width: 600px) {
            right: -10%;
            top: 70%;
          }
        }
      }

      .view-web {
        display: none;
        @media screen and (max-width: 600px) {
          text-decoration: none;
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 300;
          font-size: 10px;
          line-height: 17px;
          @include flex('center', '');
          font-feature-settings: "liga" off;
          color: #ffffff;
          margin-top: 10px;
        }
      }

      .second-view-web {
        text-decoration: none;
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        @include flex('center', '');
        font-feature-settings: "liga" off;
        color: #ffffff;
        margin-left: 150px;
        margin-top: 10px;
        @media screen and (max-width: 1025px) {
          margin-left: 40px;
        }
        @media screen and (max-width: 600px) {
          display: none;
        }
      }

      @media screen and (max-width: 1025px) {
        width: 510px;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .to-portfolio-button {
      @media screen and (max-width: 1025px) {
        display: none;
      }
    }

    @media screen and (max-width: 1025px) {
      margin-top: 300px;
    }

    @media screen and (max-width: 600px) {
      margin-top: 400px;
      width: 100%;
    }

    @media screen and (max-width: 431px) {
      margin-top: 350px;
    }
  }

  .hybrid-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 100px;
    width: 100%;
    position: relative;
    max-width: 1440px;

    .hybrid-block-content {
      width: 623px;

      .stroke-hybrid-block {
        position: relative;
        width: 100%;
        max-width: 1440px;
        @include flex('', 'right');

        .stroke-hybrid {
          position: absolute;
          height: 70px;
          z-index: 10;
          right: 95%;
          top: -10%;

          img {
            width: 346px;
            height: 68px;
            @media screen and (max-width: 376px) {
              width: 305px;
              height: 60px;
            }
          }

          h2 {
            position: absolute;
            height: 45px;
            left: 25%;
            top: 25%;
            font-family: "Inter", serif;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 160%;
            text-align: center;
            text-transform: uppercase;
            font-feature-settings: "liga" off;
            color: #ffffff;
            @media screen and (max-width: 1025px) {
              font-size: 26px;
            }
            @media screen and (max-width: 600px) {
              font-size: 24px;
            }
          }

          @media screen and (max-width: 1025px) {
            right: 82%;
            top: -15%;
          }
          @media screen and (max-width: 720px) {
            right: -5%;
            top: -20%;
          }
        }

        .rectangle-hybrid {
          box-sizing: border-box;
          width: 100%;
          background: var(--color-primary);
          @include flex('center', 'start');

          .text-hybrid {
            width: 393px;
            font-family: "Inter", serif;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 175%;
            font-feature-settings: "liga" off;
            color: #ffffff;
            @include flex('center', '');
            flex-direction: column;
            margin: 67px 150px 67px 80px;
            @media screen and (max-width: 1025px) {
              margin: 48px 40px 48px 100px;
              width: 327px;
              font-size: 22px;
            }
            @media screen and (max-width: 600px) {
              width: 100%;
              margin: 40px 18px 82px 18px;
              font-size: 20px;
              justify-content: center;
            }
          }

          @media screen and (max-width: 600px) {
            justify-content: normal;
          }
        }

        .laptop-block {
          position: absolute;
          width: 100%;
          right: 55%;
          top: 50%;

          .laptop {
            position: absolute;
            right: 38%;

            .laptop-and-phone {
              width: 400px;
              @media screen and (max-width: 1025px) {
                width: 350px;
              }
              @media screen and (max-width: 700px) {
                width: 320px;
              }
              @media screen and (max-width: 680px) {
                width: 290px;
              }
              @media screen and (max-width: 640px) and (min-width: 600px) {
                width: 250px;
              }
              @media screen and (max-width: 431px) {
                width: 261px;
                height: 162px;
              }
            }

            @media screen and (max-width: 600px) {
              right: -10%;
            }
          }

          .laptop-signature {
            position: absolute;
            left: 3%;
            top: 25%;
            @include flex('center', 'center');
            flex-direction: column;

            .das-comp {
              text-decoration: none;
              font-family: "Inter", serif;
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 22px;
              @include flex('center', '');
              font-feature-settings: "liga" off;
              color: #ffffff;
              transform: rotate(-90deg);
              @media screen and (max-width: 1025px) {
                font-size: 16px;
              }
              @media screen and (max-width: 431px) {
                font-size: 12px;
              }
            }

            .icon3 {
              width: 50%;
              font-family: "Inter", serif;
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 22px;
              @include flex('center', '');
              font-feature-settings: "liga" off;
              color: #ffffff;
              transform: rotate(-90deg);
              margin-top: 10px;
            }

            @media screen and (max-width: 1025px) {
              left: 3%;
            }

            @media screen and (max-width: 431px) {
              left: 3%;
              top: 45%;
            }
          }

          &:hover {
            .das-comp {
              color: #fa8c3d;
            }
            .icon3 {
              svg {
                stroke: #fa8c3d;
              }
            }
          }

          @media screen and (max-width: 1025px) {
            right: 50%;
          }

          @media screen and (max-width: 750px) {
            right: 40%;
          }

          @media screen and (max-width: 600px) {
            right: 10%;
            top: 80%;
          }
        }

        @media screen and (min-width: 1440px) {
          width: 200%;
        }
      }

      .rectangle-hybrid-signature {
        display: none;
        @media screen and (max-width: 600px) {
          width: 100%;
          @include flex('center', 'right');

          .view-hybrid {
            font-family: "Inter", serif;
            font-style: normal;
            font-weight: 300;
            font-size: 10px;
            line-height: 17px;
            text-decoration: none;
            @include flex('center', '');
            font-feature-settings: "liga" off;
            color: #ffffff;
            width: 100%;
            margin-top: 10px;

            .icon {
              display: none;
            }

            .das {
              display: none;
            }
          }
        }
      }

      .second-rectangle-hybrid-signature {
        width: 100%;
        @include flex('center', 'right');

        .view-hybrid {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          text-decoration: none;
          @include flex('center', '');
          font-feature-settings: "liga" off;
          color: #ffffff;
          width: 100%;
          margin-left: 80px;
          margin-top: 10px;

          .das-block {
            @include flex('center', 'center');
            text-decoration: none;
            .icon {
              height: 11.95px;
              margin: 0 5px;
              @include flex('center', '');
            }

            .das {
              width: 36px;
              font-family: "Inter", serif;
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 22px;
              @include flex('center', '');
              font-feature-settings: "liga" off;
              color: #ffffff;
              @media screen and (max-width: 1025px) {
                font-size: 16px;
              }
              @media screen and (max-width: 431px) {
                font-size: 12px;
              }
            }

            &:hover {
              .icon {
                svg {
                  stroke: #fa8c3d;
                }
              }
              .das {
                color: #fa8c3d;
              }
            }
          }

          @media screen and (max-width: 1025px) {
            margin-left: 100px;
          }
        }

        @media screen and (max-width: 600px) {
          display: none;
        }
      }

      @media screen and (max-width: 1025px) {
        width: 467px;
        height: 213px;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 1025px) {
      margin-top: 300px;
    }
    @media screen and (max-width: 431px) {
      margin-top: 350px;
    }
  }

  .second-to-portfolio-button {
    display: none;
    @media screen and (max-width: 1025px) {
      display: flex;
      justify-content: right;
      margin-top: 15%;
    }
    @media screen and (max-width: 600px) {
      margin-top: 20%;
    }
  }

  @media screen and (min-width: 1441px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    padding: 0 2%;
  }
}
