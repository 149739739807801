@import './src/styles/mixins';

:root {
  --color-primary: #fe6a00;
}

.advantages-block {
  width: 100%;
  left: 0;
  padding: 0 150px;
  margin-top: 10%;

  &:before {
    display: block;
    content: "";
    height: 100px;
    margin: -100px 0 0;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 15px;
  }

  .advantages-title {
    width: 100%;
    font-family: "Inter", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    text-transform: uppercase;
    font-feature-settings: "liga" off;
    color: var(--color-primary);
    margin-bottom: 50px;
    @media screen and (min-width: 1441px) {
      max-width: 1440px;
    }
    @media screen and (max-width: 1025px) {
      font-size: 40px;
    }
    @media screen and (max-width: 600px) {
      font-size: 36px;
    }
  }

  .advantages-content {
    width: 100%;

    .row-top {
      @include flex('center', 'flex-end');
      flex-direction: row;
      padding: 0;
      gap: 40px;
      margin-bottom: 100px;

      .wave {
        position: relative;
        width: 13%;
        left: -15%;
        top: 100px;
        @media screen and (min-width: 1441px) {
          left: -20%;
          width: 20%;
          img {
            width: 400%;
          }
        }
        @media screen and (min-width: 1990px) {
          left: -30%;
        }
        @media screen and (min-width: 2270px) {
          left: -35%;
        }
        @media screen and (min-width: 2400px) {
          left: -40%;
        }
        @media screen and (max-width: 1025px) and (min-width: 686px) {
          position: absolute;
          top: 115%;
          left: 70%;
          width: 245px;
          height: 20px;
          img {
            width: 100%;
          }
        }
        @media screen and (max-width: 685px) {
          position: absolute;
          top: 102%;
          left: 70%;
          width: 245px;
          height: 20px;
          img {
            width: 100%;
          }
        }
        @media screen and (max-width: 600px) {
          img {
            width: 100%;
            height: 20px;
          }
        }
        @media screen and (max-width: 476px) {
          top: -8%;
          left: -15%;
        }
        @media screen and (max-width: 375px) {
          left: -25%;
        }
      }

      .block-development {
        box-sizing: border-box;
        width: 100%;
        height: 284px;
        background: #ffffff;
        border: 2px solid #131313;
        position: relative;
        padding: 24px 32px;

        .title-development {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
          text-transform: uppercase;
          font-feature-settings: "pnum" on, "lnum" on, "liga" off;
          color: var(--color-primary);
          z-index: 10;
          margin-left: 1rem;
          @media screen and (max-width: 1025px) {
            font-size: 20px;
          }
          @media screen and (max-width: 600px) {
            font-size: 18px;
          }
        }

        .content-development {
          width: 100%;
          z-index: 10;
        }

        .bg-development {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 5px;
          top: 5px;
          background: #131313;
          border: 2px solid var(--color-primary);
          z-index: -1;
        }

        @media screen and (max-width: 1340px) {
          height: 300px;
        }

        @media screen and (max-width: 1025px) {
          padding: 32px 24px;
        }
        @media screen and (max-width: 685px) {
          height: 100%;
        }
        @media screen and (max-width: 390px) {
          padding: 24px 16px;
        }
      }

      .block-adjustable {
        box-sizing: border-box;
        width: 100%;
        height: 284px;
        background: #ffffff;
        border: 2px solid #131313;
        position: relative;
        padding: 24px 32px;

        .title-adjustable {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
          text-transform: uppercase;
          font-feature-settings: "pnum" on, "lnum" on, "liga" off;
          color: var(--color-primary);
          margin-left: 1rem;
          z-index: 10;
          @media screen and (max-width: 1025px) {
            font-size: 20px;
          }
          @media screen and (max-width: 600px) {
            font-size: 18px;
          }
        }

        .content-adjustable {
          width: 100%;
          z-index: 10;
        }

        .bg-adjustable {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 5px;
          top: 5px;
          background: #131313;
          border: 2px solid var(--color-primary);
          z-index: -1;
        }

        @media screen and (max-width: 1340px) {
          height: 300px;
        }

        @media screen and (max-width: 1025px) {
          padding: 32px 24px;
        }
        @media screen and (max-width: 685px) {
          height: 100%;
        }
        @media screen and (max-width: 390px) {
          padding: 24px 16px;
        }
      }

      @media screen and (max-width: 685px) {
        flex-direction: column;
        margin-bottom: 7%;
      }

      @media screen and (max-width: 500px) {
        margin-bottom: 10%;
      }

      @media screen and (max-width: 360px) {
        margin-bottom: 14%;
      }
    }

    .row-bottom {
      @include flex('center', 'flex-end');
      padding: 0;
      gap: 40px;
      margin-bottom: 100px;

      .block-projects {
        box-sizing: border-box;
        width: 100%;
        height: 284px;
        background: #ffffff;
        border: 2px solid #131313;
        position: relative;
        padding: 24px 32px;

        .title-projects {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
          text-transform: uppercase;
          font-feature-settings: "pnum" on, "lnum" on, "liga" off;
          color: var(--color-primary);
          margin-left: 1rem;
          z-index: 10;
          @media screen and (max-width: 1025px) {
            font-size: 20px;
          }
          @media screen and (max-width: 600px) {
            font-size: 18px;
          }
        }

        .content-projects {
          width: 100%;
          z-index: 10;
        }

        .bg-projects {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 5px;
          top: 5px;
          background: #131313;
          border: 2px solid var(--color-primary);
          z-index: -1;
        }

        @media screen and (max-width: 1340px) {
          height: 300px;
        }

        @media screen and (max-width: 1025px) {
          padding: 32px 24px;
        }
        @media screen and (max-width: 685px) {
          height: 100%;
        }
        @media screen and (max-width: 390px) {
          padding: 24px 16px;
        }
      }

      .block-guarantee {
        box-sizing: border-box;
        width: 100%;
        height: 284px;
        background: #ffffff;
        border: 2px solid #131313;
        position: relative;
        padding: 24px 32px;

        .title-guarantee {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
          text-transform: uppercase;
          font-feature-settings: "pnum" on, "lnum" on, "liga" off;
          color: var(--color-primary);
          margin-left: 1rem;
          z-index: 10;
          @media screen and (max-width: 1025px) {
            font-size: 20px;
          }
          @media screen and (max-width: 600px) {
            font-size: 18px;
          }
        }

        .content-guarantee {
          width: 100%;
          z-index: 10;
        }

        .bg-guarantee {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 5px;
          top: 5px;
          background: #131313;
          border: 2px solid var(--color-primary);
          z-index: -1;
        }

        @media screen and (max-width: 1340px) {
          height: 300px;
        }

        @media screen and (max-width: 1025px) {
          padding: 32px 24px;
        }
        @media screen and (max-width: 685px) {
          height: 100%;
        }
        @media screen and (max-width: 390px) {
          padding: 24px 16px;
        }
      }

      .desktop-offer-button-wrapper {
        width: 17%;
        position: relative;
        top: 120px;

        .content-wrapper {
          transform: rotate(90deg);
          display: inline-block;
          position: relative;
          z-index: 1;

          .wrapper {
            background: #131313;
            border: 2px solid var(--color-primary);
            position: relative;

            .offer-button {
              @include flex('center', 'space-between');
              text-decoration: none;
              color: var(--color-primary);
              font-family: "Inter", serif;
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 150%;
              text-transform: uppercase;
              font-feature-settings: "pnum" on, "lnum" on, "liga" off;
              position: relative;
              margin: 13px 56px 13px 36px;

              .text-wrapper {
                white-space: nowrap
              }

              .vector-wrapper {
                display: inline-block;
                transform: rotate(270deg);
                margin-left: 30px;
                width: 16px;
                height: 47px;
              }

              @media screen and (max-width: 391px) {
                flex-direction: column;
              }
            }

            &::after {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 5px;
              top: 5px;
              background: #131313;
              border: 2px solid var(--color-primary);
              z-index: -1;
              content: "";
            }

            &:hover {
              left: 4px;
              top: 4px;
              cursor: pointer;

              &::after {
                display: none;
              }
            }
          }

          @media screen and (max-width: 391px) {
            @include flex('center', 'center');
            flex-direction: column;
          }
        }

        @media screen and (min-width: 1441px) {
          width: 8%;
          left: -7%;
        }

        @media screen and (max-width: 1200px) {
          width: 13%;
          left: -10%;
        }

        @media screen and (max-width: 1025px) {
          display: none;
        }
      }

      @media screen and (max-width: 685px) {
        flex-direction: column;
        height: 100%;
      }

      @media screen and (max-width: 476px) {
        margin-bottom: 50px;
      }
    }

    .offer-button-wrapper {
      display: none;

      @media screen and (max-width: 1025px) {
        display: flex;
        justify-content: flex-end;
        position: relative;
        right: -30px;
        z-index: 1;
        .content-wrapper {
          position: relative;
          display: inline-block;
          background: #131313;
          border: 2px solid var(--color-primary);

          .offer-button {
            @include flex('center', 'center');
            gap: 10px;
            text-decoration: none;
            color: var(--color-primary);
            font-family: "Inter", serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 150%;
            text-transform: uppercase;
            font-feature-settings: "pnum" on, "lnum" on, "liga" off;
            position: relative;
            margin: 13px 56px 13px 36px;

            .text-wrapper {
              white-space: nowrap;
              position: relative;
            }

            .vector-wrapper {
              display: inline-block;
              transform: rotate(270deg);
              margin-left: 20px;
              width: 16px;
              height: 47px;

              @media screen and (max-width: 476px) {
                display: none;
              }
            }

            @media screen and (max-width: 1025px) {
              font-size: 20px;
            }

            @media screen and (max-width: 476px) {
              width: 100%;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin: 0;
              font-size: 18px;
            }
          }

          &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 5px;
            left: 5px;
            background: #131313;
            border: 2px solid var(--color-primary);
            z-index: -1;
            content: "";
          }

          &:hover {
            left: 4px;
            top: 4px;

            &::after {
              display: none;
            }
          }

          @media screen and (max-width: 476px) {
            @include flex('center', 'center');
            width: 100%;
            height: 67px;
          }
        }

        @media screen and (max-width: 600px) {
          right: -13px;
        }

        @media screen and (max-width: 476px) {
          right: 0;
        }
      }
    }

    .items {
      font-family: "Inter", serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 180%;
      font-feature-settings: "liga" off;
      color: #000000;
      flex: none;
      order: 1;
      flex-grow: 0;
      @media screen and (max-width: 1025px) {
        font-size: 16px;
      }
    }

    @media screen and (min-width: 1441px) {
      width: 1440px;
    }
  }

  @media screen and (min-width: 1441px) {
    @include flex('center', 'center');
    flex-direction: column;
  }
}
