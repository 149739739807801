@import './src/styles/mixins';

:root {
  --color-primary: #fe6a00;
}

.header {
  width: 100%;
  height: 93px;
  margin: 0 auto;
  z-index: 999;
  background-color: #131313;
  position: fixed;
  border-bottom: 0.01px solid #3e3e3e;
  @include flex('center', 'center');

  .header-content {
    width: 100%;
    max-width: 1440px;
    @include flex('center', 'space-between');
    padding: 0 150px;

    .logo-wrapper {
      .logo {
        margin-right: 10px;
        @media screen and (max-width: 431px) {
          width: 100%;
        }
        @media screen and (max-width: 391px) {
          width: 120%;
        }
        @media screen and (max-width: 321px) {
          width: 140%;
        }
      }

      @media screen and (max-width: 1025px) {
        width: 30%;
      }
    }

    .dropdown {
      .language {
        background: #131313;
        color: #ffffff;
        border: 0;
        @include flex('center', '');
        flex-direction: row;
        gap: 5px;
        font-size: 14px;
        font-weight: 300;

        &:hover, &:focus {
          cursor: pointer;
          color: var(--color-primary);
          background-color: #2f2f2f;
          border: 0;
          box-shadow: none;
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        height: 78px;
        background: #131313;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 0;
        min-width: 49px;

        .dropdown-item {
          font-family: "Inter", serif;
          font-style: normal;
          font-size: 14px;
          font-feature-settings: "pnum" on, "lnum" on, "liga" off;
          color: white;
          text-decoration: none;
          box-sizing: border-box;
          margin-top: 2px;
        }

        .dropdown-item:hover, .dropdown-item:focus {
          background-color: #131313;
          color: var(--color-primary);
          cursor: pointer;
        }
      }

      @media screen and (max-width: 1025px) and (min-width: 601px) {
        display: none;
      }

      @media screen and (max-width: 391px) {
        position: relative;
        left: 5%;
      }
    }

    @include offerButton();
    @media screen and (min-width: 1441px) {
      padding: 0;
    }
    @media screen and (max-width: 1025px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 15px;
      .btn-header {
        display: none;
      }
    }
  }

  @media screen and (max-width: 769px) {
    height: 80px;
  }

  @media screen and (max-width: 576px) {
    height: 56px;
  }
}