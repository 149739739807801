:root {
    --color-primary: #fe6a00;
}

.App {
    background: #131313;
    width: 100%;
    margin: 0 auto;
}

body {
    background: #131313;
    overflow-x: hidden;
}

.App {
    overflow: hidden;
}

.orange {
    color: var(--color-primary);
}
