@import './src/styles/mixins';

:root {
  --color-primary: #fe6a00;
}

.form {
  position: relative;
  width: 100%;
  margin-top: 400px;
  padding: 0 150px;

  &:before {
    display: block;
    content: "";
    height: 100px;
    margin: -100px 0 0;
  }

  .title {
    font-family: "Inter", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    max-width: 1440px;
    width: 100%;
    text-transform: uppercase;
    font-feature-settings: "liga" off;
    color: var(--color-primary);
    margin-bottom: 40px;
    @media screen and (max-width: 1360px) {
      font-size: 40px;
    }
    @media screen and (max-width: 600px) {
      font-size: 36px;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 431px) {
      text-align: center;
    }
  }

  .form-block {
    @include flex('', 'space-between');

    .choice {
      @include flex('flex-start', '');
      flex-direction: column;
      padding: 0;
      width: 940px;

      .app-development-button-wrapper {
        .button {
          @include flex('center', 'center');
          gap: 10px;
          width: 210px;
          height: 45px;
          background: #fe6a00;
          border: 1px solid var(--color-primary);
          z-index: 1;
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 140%;
          font-feature-settings: "liga" off;
          color: #ffffff;
          flex: none;
          order: 0;
          flex-grow: 0;
          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
          @media screen and (max-width: 431px) {
            width: 165px;
            height: 36px;
          }
        }

        @media screen and (max-width: 1360px) {
          width: 100%;
          @include flex('center', 'center');
        }
      }

      .text {
        width: 100%;
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        font-feature-settings: "liga" off;
        color: #ffffff;

        .buttons {
          @include flex('', 'start');
          flex-wrap: wrap;
          padding: 0;
          gap: 0;
          width: 100%;
          margin-top: 24px;

          .button-active, .button-component {
            box-sizing: border-box;
            display: flex;
            padding: 10px 26px;
            border: 1px solid #ffffff;
            border-radius: 60px;
            font-family: "Inter", serif;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 140%;
            font-feature-settings: "liga" off;
            color: #ffffff;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 0 20px 20px 0;

            @media screen and (max-width: 1025px) {
              margin: 0 18px 18px 0;
              padding: 8px 24px;
            }

            @media screen and (max-width: 600px) {
              margin: 0 10px 10px 0;
              padding: 8px 16px;
            }

            @media screen and (max-width: 431px) {
              font-size: 13px;
            }
          }

          .button-active {
            background-color: var(--color-primary);
            border: 1px solid transparent;
          }

          .button-component {
            background-color: #131313;
            text-align: center;

            &:hover {
              border: 1px solid var(--color-primary);
            }
          }

          @media screen and (max-width: 1360px) {
            width: 100%;
          }
        }

        @media screen and (max-width: 1025px) {
          font-size: 22px;
        }

        @media screen and (max-width: 600px) {
          font-size: 18px;
        }
      }

      .buttons-error {
        color: red;
      }

      @media screen and (max-width: 1360px) {
        width: 100%;
      }
    }

    .line {
      width: 5%;
      @include flex('', 'right');

      .vertical-line {
        @media screen and (max-width: 1360px) {
          display: none;
        }
      }

      .horizontal-line {
        display: none;
        @media screen and (max-width: 1360px) {
          display: flex;
        }
      }

      @media screen and (max-width: 1360px) {
        justify-content: center;
        height: 100px;
      }
    }

    .img {
      @include flex('flex-end', 'center');
      flex-direction: column;
      padding: 0;
      gap: 60px;
      width: 5%;
      @media screen and (max-width: 1360px) {
        flex-direction: row;
      }
    }

    .images {
      width: 48px;
      height: 48px;
    }

    @media screen and (min-width: 1441px) {
      max-width: 1440px;
      width: 100%;
    }

    @media screen and (max-width: 1360px) {
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (min-width: 1441px) {
    @include flex('center', 'center');
    flex-direction: column;
  }

  @media screen and (max-width: 1360px) {
    padding: 0 40px;
    margin-top: 300px;
  }

  @media screen and (max-width: 600px) {
    padding: 0 15px;
    margin-top: 200px;
  }
}
