@import './src/styles/mixins';

:root {
  --color-primary: #fe6a00;
}

.team {
  width: 100%;
  padding: 0 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:before {
    display: block;
    content: "";
    height: 150px;
    margin: -150px 0 0;
  }

  .title-team {
    display: none;
    @media screen and (max-width: 1025px) {
      display: flex;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 140%;
      text-transform: uppercase;
      font-feature-settings: "liga" off;
      color: var(--color-primary);
      margin-bottom: 100px;
      @media screen and (max-width: 1025px) {
        font-size: 40px;
      }
      @media screen and (max-width: 600px) {
        font-size: 36px;
      }
      @media screen and (max-width: 431px) {
        margin-bottom: 50px;
      }
    }
  }

  .team-wrapper {
    width: 100%;
    .first-line {
      @include flex('center', 'space-between');
      width: 100%;
      height: 50%;

      .manager-block {
        @include flex('center', 'center');
        position: relative;
        width: 30%;

        .manager-text {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
          text-transform: uppercase;
          font-feature-settings: "pnum" on, "lnum" on, "liga" off;
          color: var(--color-primary);
          writing-mode: tb-rl;
          transform: rotate(180deg);
          margin-right: 5px;
          @media screen and (max-width: 1025px) {
            font-size: 20px;
          }
        }

        .photos-management {
          img {
            width: 100%;
            height: 100%;
          }

          @media screen and (max-width: 1025px) {
            width: 80%;
          }

          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }

        .vector1-wrapper {
          width: 115px;
          height: 78px;
          position: absolute;
          right: -25%;
          top: -10%;

          .vector1 {
            width: 100%;
          }

          @media screen and (max-width: 1025px) {
            right: -5%;
          }

          @media screen and (max-width: 800px) {
            right: -10%;
          }

          @media screen and (max-width: 600px) {
            right: -10%;
            top: -5%;
          }

          @media screen and (max-width: 431px) {
            width: 58px;
            height: 40px;
            right: -5%;
            top: 90%;
            transform: rotate(90deg);
          }
        }

        @media screen and (max-width: 1025px) {
          width: 50%;
          justify-content: flex-start;
        }

        @media screen and (max-width: 431px) {
          width: 100%;
        }
      }

      .designers-block {
        @include flex('center', 'center');
        position: relative;
        width: 30%;

        .designers-text {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
          text-transform: uppercase;
          font-feature-settings: "pnum" on, "lnum" on, "liga" off;
          color: var(--color-primary);
          writing-mode: tb-rl;
          transform: rotate(180deg);
          margin-right: 5px;
          @media screen and (max-width: 1025px) {
            font-size: 20px;
          }
        }

        .photos-designers {
          img {
            width: 100%;
            height: 100%;
          }

          @media screen and (max-width: 1025px) {
            width: 80%;
          }

          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }

        .vector2-wrapper {
          transform: rotate(-10deg);
          position: absolute;
          left: -5%;
          top: 100%;
          width: 115px;
          height: 78px;

          .vector2 {
            width: 55%;

            @media screen and (max-width: 1300px) {
              width: 40%;
            }

            @media screen and (max-width: 1025px) {
              width: 55%;
            }
          }

          @media screen and (max-width: 1025px) {
            transform: rotate(35deg);
            left: -15%;
          }

          @media screen and (max-width: 600px) {
            top: 100%;
          }
          @media screen and (max-width: 431px) {
            display: none;
          }
        }

        .mobile-vector2-wrapper {
          display: none;
          @media screen and (max-width: 431px) {
            display: flex;
            position: absolute;
            bottom: -25%;
            left: 5%;
            width: 48px;
            height: 71px;
            @media screen and (max-width: 321px) {
              bottom: -30%;
              left: 7%;
            }
          }
        }

        @media screen and (max-width: 1025px) {
          width: 50%;
          justify-content: flex-start;
        }

        @media screen and (max-width: 431px) {
          width: 100%;
          margin-top: 30px;
        }
      }

      .desktop-title-team {
        @include flex('center', 'center');
        width: 30%;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 140%;
        text-transform: uppercase;
        font-feature-settings: "liga" off;
        color: var(--color-primary);
        position: relative;
        left: 2%;
        @media screen and (max-width: 1025px) {
          display: none;
        }
      }

      @media screen and (max-width: 1025px) {
        justify-content: flex-start;
      }

      @media screen and (max-width: 431px) {
        flex-direction: column;
      }
    }

    .second-line {
      @include flex('center', 'space-between');
      width: 100%;
      height: 50%;
      margin-top: 60px;

      .desktop-btns-container {
        @include flex('center', 'center');
        flex-direction: column;
        width: 30%;
        cursor: pointer;

        .btns-text {
          text-decoration: none;
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
          @include flex('center', 'center');
          flex-direction: column;
          text-transform: uppercase;
          font-feature-settings: "pnum" on, "lnum" on, "liga" off;
          color: #606060;
          text-align: center;
        }

        .vector-wrapper {
          .vector {
            opacity: 0.2;
          }
        }

        &:hover {
          .btns-text {
            color: #ffffff;
          }

          .vector {
            opacity: 1;
          }
        }

        @media screen and (max-width: 1025px) {
          display: none;
        }
      }

      .developers-block {
        @include flex('center', 'center');
        position: relative;
        width: 30%;

        .photos-developers {
          img {
            width: 100%;
            height: 100%;
          }

          @media screen and (max-width: 1025px) {
            width: 80%;
          }

          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }

        .text-developers {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
          text-transform: uppercase;
          font-feature-settings: "pnum" on, "lnum" on, "liga" off;
          color: var(--color-primary);
          writing-mode: tb-rl;
          transform: rotate(180deg);
          margin-right: 5px;
          @media screen and (max-width: 1025px) {
            font-size: 20px;
          }
        }

        .vector3-wrapper {
          width: 115px;
          height: 78px;
          position: absolute;
          left: 90%;
          top: 100%;

          .vector3 {
            width: 100%;
          }

          //@media screen and (max-width: 1025px) {
          //  left: 100%;
          //  top: 100%;
          //}
          @media screen and (max-width: 1025px) {
            left: 85%;
            top: 95%;
          }
          @media screen and (max-width: 600px) {
            left: 85%;
            top: 95%;
          }
          @media screen and (max-width: 431px) {
            display: none;
          }
        }

        .vector4-wrapper {
          display: none;
          @media screen and (max-width: 431px) {
            display: flex;
            transform: rotate(90deg);
            position: absolute;
            right: -2%;
            top: 90%;
            width: 53px;
            height: 36px;
          }

          .vector4 {
            width: 100%;
          }
        }

        @media screen and (max-width: 1025px) {
          width: 50%;
          justify-content: flex-end;
        }

        @media screen and (max-width: 431px) {
          width: 100%;
        }
      }

      .qa-block {
        @include flex('center', 'center');
        position: relative;
        width: 30%;

        .photos-qa {
          img {
            width: 100%;
            height: 100%;
          }

          @media screen and (max-width: 1025px) {
            width: 80%;
          }

          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }

        .text-qa {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
          text-transform: uppercase;
          font-feature-settings: "pnum" on, "lnum" on, "liga" off;
          color: var(--color-primary);
          writing-mode: tb-rl;
          transform: rotate(180deg);
          margin-right: 5px;
          @media screen and (max-width: 1025px) {
            font-size: 20px;
          }
        }

        @media screen and (max-width: 1025px) {
          width: 50%;
          justify-content: flex-end;
        }

        @media screen and (max-width: 431px) {
          width: 100%;
          margin-top: 30px;
        }
      }

      @media screen and (max-width: 1025px) {
        justify-content: flex-end;
      }

      @media screen and (max-width: 431px) {
        flex-direction: column;
        margin-top: 30px;
      }
    }

    .btns-container {
      display: none;
      @media screen and (max-width: 1025px) {
        @include flex('flex-end', 'center');
        flex-direction: column;
        margin-top: 100px;
        cursor: pointer;

        .wrapper {
          @include flex('center', 'center');
          flex-direction: column;
          cursor: pointer;

          .btns-text {
            text-decoration: none;
            font-family: "Inter", serif;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            @include flex('center', 'center');
            flex-direction: column;
            text-transform: uppercase;
            font-feature-settings: "pnum" on, "lnum" on, "liga" off;
            color: #606060;
            text-align: center;
            @media screen and (max-width: 431px) {
              font-size: 18px;
              margin-top: 50px;
            }
          }

          .vector {
            opacity: 0.2;
          }

          &:hover {
            .btns-text {
              color: #ffffff;
            }

            .vector {
              opacity: 1;
            }
          }
        }
      }
      @media screen and (max-width: 431px) {
        align-items: center;
        margin-top: 0;
      }
    }

    @media screen and (min-width: 1441px) {
      width: 1440px;
    }
  }

  @media screen and (min-width: 1441px) {
    margin-top: 300px;
    padding: 0;
  }

  @media screen and (max-width: 1500px) {
    margin-top: 30%;
  }

  @media screen and (max-width: 1025px) {
    padding: 0 40px;
    margin-top: 100px;
  }

  @media screen and (max-width: 600px) {
    padding: 0 15px;
  }
}
