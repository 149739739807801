@import './src/styles/mixins';

.menu-header {
  padding: 0;
  width: 50%;
  @media screen and (max-width: 1025px) {
    display: none;
  }

  ul {
    @include flex('center', 'space-between');
    margin: 0;
    padding: 0;

    a {
      text-decoration: none;

      li {
        @include flex('center', 'center');
      }
    }
  }
}

.menu-items {
  height: 22px;
  display: inline-block;
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 160%;
  font-feature-settings: "liga" off;
  color: #ffffff;
  flex: none;
  flex-grow: 0;
}

.menu-items:hover,
.menu-items:focus {
  color: var(--color-primary);
}

@media (max-width: 768px) {
  .menu-header {
    display: none;
  }
}

@media (max-width: 320px) {
  .menu-header {
    display: none;
  }
}
