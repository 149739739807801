@import './src/styles/mixins';

:root {
  --color-primary: #fe6a00;
}

.advertising-content {
  position: relative;
  width: 100%;
  margin-top: 200px;

  .title-advertising {
    font-family: "Inter", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    text-transform: uppercase;
    font-feature-settings: "liga" off;
    color: var(--color-primary);
    transform: rotate(5deg);
    padding-left: 150px;

    @media screen and (min-width: 1441px) {
      max-width: 1440px;
      width: 100%;
      padding-left: 0;
    }

    @media screen and (max-width: 1025px) {
      font-size: 40px;
      padding-left: 40px;
      transform: rotate(7deg);
    }

    @media screen and (max-width: 769px) {
      font-size: 36px;
      padding-left: 15px;
      transform: rotate(10deg);
    }
  }

  @media screen and (min-width: 1441px) {
    @include flex('center', 'secnter');
    flex-direction: column;
  }

  @media screen and (max-width: 1025px) {
    margin-top: 100px;
  }
}
