@import './src/styles/mixins';

.form-wrapper {
  width: 100%;
  margin-top: 16px;

  .form-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .group1 {
      @include flex('center', 'space-between');
      padding: 0;
      gap: 30px;
      width: 100%;

      .control {
        box-sizing: border-box;
        background-color: #131313;
        border: 1px solid #ffffff;
        width: 454px;
        height: 64px;
        flex: none;
        order: 0;
        flex-grow: 0;
        display: inline;
        color: #ffffff;
        padding: 16px 24px;
        font-size: 18px;
        @media screen and (max-width: 1360px) {
          width: 50%;
        }
        @media screen and (max-width: 1360px) {
          width: 100%;
        }
        @media screen and (max-width: 1024px) {
          padding: 14px 18px;
        }
        @media screen and (max-width: 600px) {
          padding: 10px 10px;
          height: 48px;
          font-size: 16px;
        }
      }

      .email {
        width: 940px;
        height: 64px;
        box-sizing: border-box;
        border: 1px solid #ffffff;
        background-color: #131313;
        color: #ffffff;
        padding: 16px 24px;
        font-size: 18px;
        @media screen and (max-width: 1360px) {
          width: 100%;
        }
        @media screen and (max-width: 1024px) {
          padding: 14px 18px;
        }
        @media screen and (max-width: 600px) {
          padding: 10px 10px;
          height: 48px;
          font-size: 16px;
        }
      }

      .border-error {
        border: 1px solid red;
      }

      @media screen and (max-width: 1360px) {
        flex-direction: column;
        gap: 24px;
      }
    }

    .group2 {
      width: 100%;
      margin-top: 30px;

      .freeText {
        width: 940px;
        box-sizing: border-box;
        border: 1px solid #ffffff;
        background-color: #131313;
        color: #ffffff;
        padding: 16px 24px;
        font-size: 18px;

        @media screen and (max-width: 1360px) {
          width: 100%;
        }

        @media screen and (max-width: 1024px) {
          padding: 14px 18px;
        }

        @media screen and (max-width: 600px) {
          padding: 10px 10px;
          font-size: 16px;
        }
      }

      .border-error {
        border: 1px solid red;
      }

      @media screen and (max-width: 769px) {
        margin-top: 24px;
      }
    }

    @media screen and (max-width: 1360px) {
      width: 100%;
    }
  }

  .inputs-error {
    color: red;
    margin: 32px 0;
    font-family: "Inter", serif;
  }

  .send-button-wrapper {
    position: relative;
    z-index: 1;

    .primary {
      box-sizing: border-box;
      @include flex('center', 'center');
      gap: 10px;
      background: #ffffff;
      border: 2px solid #131313;
      width: 378px;
      height: 76px;
      margin-top: 32px;
      position: relative;

      &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 4px;
        left: 4px;
        background: #ffffff;
        border: 1px solid #131313;
        z-index: -1;
        content: "";
      }

      .text-button {
        font-family: "Inter", serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        text-transform: uppercase;
        font-feature-settings: "pnum" on, "lnum" on, "liga" off;
        color: black;
        flex: none;
        order: 0;
        text-decoration: none;
        flex-grow: 0;
        @media screen and (max-width: 1025px) {
          font-size: 22px;
        }
        @media screen and (max-width: 600px) {
          font-size: 18px;
        }
      }

      .button-success {
        color: var(--color-primary);
      }

      @media screen and (max-width: 1360px) {
        width: 372px;
        height: 73px;
      }

      @media screen and (max-width: 431px) {
        width: 100%;
        height: 59px;
      }
    }

    .primary:hover {
      top: 4px;
      left: 4px;

      &::after {
        display: none;
      }
    }

    @media screen and (max-width: 1360px) {
      width: 100%;
      @include flex('center', 'center');
    }
  }

  .checkbox-wrapper {
    .checkbox-block {
      @include flex('center', 'left');
      margin-top: 32px;
      color: #ffffff;
      font-weight: 300;

      input[type="checkbox"] {
        display: none;
      }

      label {
        @include flex('center', 'center');
      }

      label:before {
        content: " ";
        color: #fff;
        display: inline-block;
        margin-right: 10px;
        position: relative;
        text-align: center;
        text-indent: 0;
        width: 20px;
        height: 20px;
        background: #131313;
        border: 1px solid #ffffff;
        border-image: initial;
        vertical-align: middle;
        cursor: pointer;
      }

      span {
        color: #fff;
        cursor: default;
        font-family: "Inter", serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        @media screen and (max-width: 1025px) {
          font-size: 14px;
        }
        @media screen and (max-width: 600px) {
          font-size: 12px;
        }
      }

      input:checked + label:before {
        content: "\2713";
        font-size: 13px;
        vertical-align: middle;
        background: var(--color-primary);
        border: none;
      }

      input:disabled + label:before {
        background: #eee;
        color: #aaa;
      }

      a {
        color: #ffffff;
        cursor: pointer;
        font-family: "Inter", serif;
      }
    }

    .checkbox-error {
      color: red;
      margin-top: 32px;
    }
  }

  @media screen and (max-width: 1281px) {
    margin-top: 14px;
  }

  @media screen and (max-width: 600px) {
    margin-top: 18px;
  }
}
