@import './src/styles/mixins';

.to-portfolio-block {
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  .ellipse-content {
    text-decoration: none;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
    left: 60px;

    .text-ellipse {
      width: 100%;
      font-family: "Inter", serif;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 150%;
      @include flex('', 'center');
      text-transform: uppercase;
      font-feature-settings: "pnum" on, "lnum" on, "liga" off;
      color: var(--color-primary);
      text-align: center;
      @media screen and (max-width: 1025px) {
        font-size: 20px;
      }
      @media screen and (max-width: 640px) {
        font-size: 16px;
      }
    }

    .vector3 {
      width: 8%;
      transform: rotate(90deg);
      position: relative;
      top: -15px;
      @media screen and (max-width: 769px) {
        width: 5%;
        top: -10px;
      }
      @media screen and (max-width: 431px) {
        width: 5.5%;
        margin-bottom: 30px;
      }
    }
    @media screen and (max-width: 1025px) {
      left: 25px;
    }
    @media screen and (max-width: 431px) {
      left: 40px;
    }
  }

  @media screen and (max-width: 1025px) {
    transform: rotate(90deg);
    justify-content: center;
  }
  @media screen and (max-width: 1025px) {
    left: 1.5%;
  }
  @media screen and (max-width: 600px) {
    left: -0.5%;
    margin-top: 50px;
  }
  @media screen and (max-width: 375px) {
    left: -2%;
  }
  @media screen and (max-width: 320px) {
    left: -4.5%;
  }

  .white-ellipse {
    @media screen and (max-width: 1025px) {
      display: none;
    }
  }

  .second-white-ellipse {
    display: none;
    @media screen and (max-width: 1025px) {
      transform: rotate(-90deg);
      display: flex;
      width: 260px;
      height: 130px;
    }
    @media screen and (max-width: 431px) {
      width: 240px;
      height: 120px;
    }
  }
  @media screen and (max-width: 1025px) {
    position: relative;
    left: -90px;
  }
  @media screen and (max-width: 1025px) {
    position: initial;
    width: 260px;
    height: 130px;
    margin-top: 200px;
  }
  @media screen and (max-width: 431px) {
    width: 240px;
    height: 120px;
    position: relative;
    left: 7px;
  }
  @media screen and (max-width: 391px) {
    margin-top: 200px;
  }
}