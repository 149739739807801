@mixin flex($alignItems: 'center', $justifyContent: 'center') {
  display: flex;
  align-items: #{$alignItems};
  justify-content: #{$justifyContent};
}

@mixin offerButton() {
  .btn-header {
    white-space: nowrap;
    box-sizing: border-box;
    @include flex('center', 'center');
    gap: 10px;
    padding: 10px 24px;
    background: #131313;
    border: 1px solid var(--color-primary);
    text-decoration: none;
    font-family: "Inter", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 157.9%;
    text-transform: uppercase;
    font-feature-settings: "pnum" on, "lnum" on, "liga" off;
    color: var(--color-primary);
    position: relative;

    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 4px;
      left: 4px;
      background: #131313;
      border: 1px solid var(--color-primary);
      z-index: -2;
      content: "";
    }

    &:hover {
      top: 4px;
      left: 4px;
      color: var(--color-primary);

      &::after {
        display: none;
      }
    }
  }
}