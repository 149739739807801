@import './src/styles/mixins';

@font-face {
  font-family: Inter;
  src: url(../../fonts/Inter-VariableFont_slnt_wght.ttf);
}

:root {
  --color-primary: #fe6a00;
}

.orange {
  color: var(--color-primary);
}

.main-content {
  width: 100%;
  @include flex('center', 'center');
  flex-direction: column;
}

.main-title {
  margin-top: 156px;
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 140px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-feature-settings: "liga" off;
  color: var(--color-primary);
  text-align: center;
  width: 100%;
  @media screen and (max-width: 1080px) {
    font-size: 120px;
  }
  @media screen and (max-width: 920px) {
    font-size: 95px;
  }
  @media screen and (max-width: 740px) {
    font-size: 85px;
  }
  @media screen and (max-width: 660px) {
    font-size: 80px;
  }
  @media screen and (max-width: 620px) {
    font-size: 75px;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.divided-main-title {
  display: none;
  @media screen and (max-width: 600px) {
    display: flex;
    margin-top: 156px;
    font-family: "Inter", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-feature-settings: "liga" off;
    color: var(--color-primary);
    text-align: center;
    width: 100%;
  }
  @media screen and (max-width: 376px) {
    font-size: 60px;
  }
}

.contentBlock {
  margin-top: 80px;
  margin-left: 150px;
  @include flex('', 'space-between');

  .contentText {
    @include flex('flex-start', '');
    flex-direction: column;
    padding: 0;
    width: 45%;
    position: relative;
    z-index: 1;

    .text-main {
      text-align: left;
      width: 97%;
      font-family: "Inter", serif;
      font-weight: 300;
      font-size: 20px;
      line-height: 35px;
      font-feature-settings: "liga" off;
      color: #ffffff;
      margin-bottom: 40px;
      @media screen and (max-width: 1025px) {
        width: 100%;
      }
      @media screen and (max-width: 600px) {
        font-size: 16px;
        font-weight: 300;
      }
    }

    .button-get-wrapper {
      position: relative;
      width: 97%;
      .button-get {
        box-sizing: border-box;
        @include flex('center', 'center');
        gap: 10px;
        width: 100%;
        height: 76px;
        background: white;
        border: 1px solid #131313;
        text-decoration: none;
        position: relative;
        padding: 20px 156px;

        img {
          width: 71px;
          height: 24px;
          position: relative;
          top: -8px;

          @media screen and (max-width: 1025px) {
            top: -5px;
          }

          @media screen and (max-width: 391px) {
            width: 59px;
            height: 20px;
          }
        }

        .text-button {
          font-family: "Inter", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 150%;
          text-transform: uppercase;
          font-feature-settings: "pnum" on, "lnum" on, "liga" off;
          color: var(--color-primary);
          flex: none;
          order: 0;
          flex-grow: 0;
          text-decoration: none;

          @media screen and (max-width: 1025px) {
            font-size: 22px;
          }

          @media screen and (max-width: 600px) {
            font-size: 18px;
          }
        }

        .text-button:hover {
          color: var(--color-primary);
        }

        .vector {
          @media screen and (max-width: 600px) {
            display: none;
          }
        }

        @media screen and (max-width: 1025px) {
          width: 100%;
        }

        @media screen and (max-width: 420px) {
          padding: 0;
          height: 67px;
        }

        @media screen and (max-width: 321px) {
          max-width: 320px;
          height: 67px;
        }
      }

      &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 4px;
        left: 4px;
        background: white;
        border: 1px solid #131313;
        z-index: -2;
        content: "";
      }

      &:hover {
        top: 4px;
        left: 4px;
        &::after {
          display: none;
        }
      }
    }

    @media screen and (max-width: 1025px) {
      width: 80%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .scroll-container {
    position: relative;
    z-index: 10;
    overflow: visible;
    text-align: center;

    .circle {
      position: relative;
      @include flex('center', 'center');

      .ellipse-block {
        width: 100%;

        .ellipse {
          width: 494px;
          height: 494px;
          @media screen and (max-width: 1240px) {
            width: 430px;
            height: 430px;
          }
          @media screen and (max-width: 1100px) {
            width: 400px;
            height: 400px;
          }
          @media screen and (max-width: 769px) {
            width: 330px;
            height: 330px;
          }
          @media screen and (max-width: 431px) {
            width: 194px;
            height: 194px;
          }
        }

        .to-portfolio-button {
          box-sizing: border-box;
          position: absolute;
          width: 350px;
          height: 350px;
          left: -35%;
          top: 50%;
          z-index: -5;
          @include flex('center', 'center');

          .to-portfolio-arrow {
            width: 40%;
            @media screen and (max-width: 1025px) {
              width: 45%;
              transform: rotate(-30deg);
            }
            &:hover {
              cursor: pointer;
            }
          }

          .to-portfolio-button-wrapper {
            position: absolute;
            width: 100%;
            animation: rotateGoToPortfolioButton 10s infinite linear;
            @media screen and (max-width: 1025px) {
              width: 120%;
            }
          }

          @media screen and (max-width: 1025px) {
            left: -50%;
            top: 15%;
          }
          @media screen and (max-width: 769px) {
            left: -30%;
            top: 0;
          }
          @media screen and (max-width: 700px) {
            left: -40%;
          }
          @media screen and (max-width: 650px) {
            left: -45%;
          }
          @media screen and (max-width: 580px) {
            left: -50%;
          }
          @media screen and (max-width: 550px) {
            left: -55%;
          }
          @media screen and (max-width: 520px) {
            left: -45%;
            top: 20%;
            width: 230px;
            height: 230px;
          }
          @media screen and (max-width: 431px) {
            left: -35%;
            top: 0;
            width: 205px;
            height: 205px;
          }
          @media screen and (max-width: 391px) {
            left: -40%;
          }
          @media screen and (max-width: 376px) {
            left: -45%;
          }
          @media screen and (max-width: 321px) {
            left: -60%;
          }
        }
      }
    }

    .carousel {
      position: absolute;
      width: 100%;

      .phonePhoto {
        box-sizing: border-box;
        width: 294px;
        height: 597px;
        @media screen and (max-width: 1240px) {
          width: 220px;
          height: 470px;
        }
        @media screen and (max-width: 769px) {
          width: 196px;
          height: 399px;
        }
        @media screen and (max-width: 431px) {
          width: 104px;
          height: 225px;
        }
      }
    }

    @media screen and (max-width: 1025px) {
      margin-top: 100px;
      bottom: 0;
      left: 10%;
    }

    @media screen and (max-width: 769px) {
      width: 70%;
      left: 15%;
    }

    @media screen and (max-width: 321px) {
      left: 20%;
    }
  }

  @media screen and (min-width: 1441px) {
    max-width: 1440px;
    @include flex('center', 'space-between');
    margin: 80px 0 0 0;
  }

  @media screen and (max-width: 1025px) {
    margin: 48px 40px 0 40px;
    flex-direction: column;
    @include flex('center', 'center');
  }

  @media screen and (max-width: 600px) {
    margin: 24px 15px 100px 15px;
  }
}

@keyframes rotateGoToPortfolioButton {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
