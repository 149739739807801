@import './src/styles/mixins';

.clients {
  width: 120%;
  margin-top: 30px;

  .row-top {
    border-top: 2px solid var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
    @include flex('center', '');
    padding: 0;
    position: absolute;
    left: -5%;
    width: 110%;
    height: 96px;
    background-color: #ffffff;
    z-index: 10;
    overflow: hidden;
    transform: rotate(-5deg);

    .logos-top {
      display: flex;
      width: calc(300px * 18);
      animation: wrapImg 15s infinite linear;

      .components {
        width: 100%;
        @include flex('', 'space-around');

        img {
          width: 200px;
          max-height: 90px;
          margin: 0 50px;
          -webkit-transform: translateZ(0);
          @media screen and (max-width: 769px) {
            height: 75px;
          }
          @media screen and (max-width: 431px) {
            height: 36px;
            margin: 0 10px;
            width: 100px;
          }
        }
      }
      @media screen and (max-width: 431px) {
        width: calc(120px * 19);
        animation: wrapImgForMedia 15s infinite linear;
      }
    }

    @media screen and (max-width: 1025px) {
      transform: rotate(-7deg);
      height: 79px;
    }

    @media screen and (max-width: 769px) {
      transform: rotate(-10deg);
    }

    @media screen and (max-width: 431px) {
      height: 40px;
    }
  }

  .row-bottom {
    border-top: 2px solid var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
    overflow: hidden;
    @include flex('center', '');
    padding: 0;
    position: absolute;
    left: -5%;
    width: 110%;
    height: 96px;
    background: #ffffff;
    transform: rotate(5deg);

    .logos-bottom {
      @include flex('center', 'center');
      width: calc(300px * 18);
      animation: wrapImg 15s infinite linear;

      .components {
        width: 100%;
        @include flex('', 'space-around');

        img {
          width: 200px;
          max-height: 90px;
          margin: 0 50px;
          -webkit-transform: translateZ(0);
          @media screen and (max-width: 769px) {
            height: 75px;
          }
          @media screen and (max-width: 431px) {
            height: 36px;
            margin: 0 10px;
            width: 100px;
          }
        }
      }
      @media screen and (max-width: 431px) {
        width: calc(120px * 19);
        animation: wrapImgForMedia 15s infinite linear;
      }
    }

    @media screen and (max-width: 1025px) {
      transform: rotate(7deg);
      height: 79px;
    }

    @media screen and (max-width: 769px) {
      transform: rotate(10deg);
    }

    @media screen and (max-width: 431px) {
      height: 40px;
    }
  }

  @keyframes wrapImg {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(calc(-300px * 9));
    }
  }

  @keyframes wrapImgForMedia {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(calc(-120px * 9));
    }
  }

  @media screen and (max-width: 480px) {
    margin-top: 50px;
  }
}
